
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { Vue, Component } from 'vue-property-decorator'
import { Services } from 'tradingmate-modules/src/services'
import Paging from '@/components/actions/Paging.vue'
import { Table, TableData, TableHeader, TableRow } from '@/components/table'
import { SearchBar, SearchBarItem } from '@/components/search'
import { InputText } from '@/components/inputs'
import Utils from 'tradingmate-modules/src/Utils'
import EmailLoggedModel from 'tradingmate-modules/src/models/api/admin/emails/logged/EmailLoggedModel'
import EmailLoggedFilter from 'tradingmate-modules/src/filters/EmailLoggedFilter'

@Component({
  components: {
    Table,
    TableRow,
    TableData,
    TableHeader,
    SearchBar,
    SearchBarItem,
    InputText,
    Paging
  }
})

export default class LoggedEmailsIndex extends Vue {
  private loggedEmails: PagedResultSet<EmailLoggedModel> | null = null
  private loggedEmailFilter = new EmailLoggedFilter({ Sender: '', Recipient: '' })
  private loading = false
  private debounce: number | undefined = undefined
  private isoToDisplayDate = Utils.isoToDisplayDate

  mounted (): void {
    this.getLoggedEmails()
  }

  handlePaged (page: number): void {
    this.loggedEmailFilter.Page = page
    this.getLoggedEmails()
  }

  handleSenderUpdated (input: string): void {
    this.loggedEmailFilter.Sender = input
    window.clearTimeout(this.debounce)
    this.debounce = window.setTimeout(() => {
      this.getLoggedEmails()
    }, 1000)
  }

  handleRecipientUpdated (input: string): void {
    this.loggedEmailFilter.Recipient = input
    window.clearTimeout(this.debounce)
    this.debounce = window.setTimeout(() => {
      this.getLoggedEmails()
    }, 1000)
  }

  getLoggedEmails (): void {
    this.loading = true
    Services.API.Admin.Emails.GetLoggedEmails(this.loggedEmailFilter)
      .then((returnData) => { this.loggedEmails = returnData })
      .finally(() => { this.loading = false })
  }
}

