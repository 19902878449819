import EmailLoggedType from '../models/api/admin/emails/logged/EmailLoggedType'
import PagedFilterBase from "./PagedFilterBase";

class EmailLoggedFilter extends PagedFilterBase {
    LoggedEmailType = EmailLoggedType.None;
    Sender?: string;
    Recipient?: string;

    constructor(init?: Partial<EmailLoggedFilter>) {
        super()

        if (init) Object.assign(this, init);
    }
}

export default EmailLoggedFilter
